export const invokeProposeTopMatch = proposeTopMatch => ({
  projectUri,
  resourceRequestUris
}) =>
  proposeTopMatch({
    variables: {
      projectUri,
      resourceRequestUris
    },
    refetchQueries: ['resourceRequestWithTimeoff']
  });
