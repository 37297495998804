import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';

const DELETE_PROJECT_LINKS = gql`
  mutation DeleteProjectLinks($input: DeleteProjectLinksInput!) {
    deleteProjectLinks(input: $input) {
      ids
    }
  }
`;

export const useRemoveProjectLinks = () => {
  const [removeProjectLinks] = useMutation(DELETE_PROJECT_LINKS);

  return {
    removeProjectLinks: async ({
      id,
      baseProject,
      targetProject,
      linkType
    }) => {
      await removeProjectLinks({
        variables: {
          input: {
            id,
            baseProjectId: baseProject.id,
            targetProjectId: targetProject.id,
            linkType
          }
        },
        skip: !id || !baseProject?.id || !targetProject?.id || !linkType,
        refetchQueries: ['getProjects']
      });
    }
  };
};

export default useRemoveProjectLinks;
