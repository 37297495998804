import React, { useMemo } from 'react';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Skeleton } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { AddResourceIcon } from '~/modules/common/components';
import { ListTableNavigation } from '../../ListTable/components/DataTable/components';
import { DEFAULT_PAGE_SIZE } from './hooks/useTaskResourceEstimates';

export const useTitleStyles = makeStyles(theme => ({
  root: {
    margin: 0
  },
  title: {
    alignContent: 'center',
    padding: theme.spacing(0, 3, 0, 0),
    fontWeight: theme.typography.fontWeightMedium
  },
  resourceCount: {
    alignContent: 'center',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular
  },
  closedExpansionPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    width: `calc(100% - ${theme.spacing(15)}px)`
  },
  button: {
    padding: 0
  }
}));

const addResourceIcon = <AddResourceIcon />;

export const TaskEstimateTitle = ({
  expanded = false,
  loading,
  onAddResourceClick,
  currentPage,
  setCurrentPage
}) => {
  const classes = useTitleStyles();

  const {
    values: { resourceEstimates }
  } = useFormikContext();

  const assignmentCount = resourceEstimates?.length || 0;
  const assignmentWithResourceCount =
    resourceEstimates?.filter(estimate => estimate.resourceUser).length || 0;

  const values = useMemo(
    () => ({
      count:
        assignmentCount === assignmentWithResourceCount
          ? assignmentCount
          : `${assignmentWithResourceCount} / ${assignmentCount}`
    }),
    [assignmentCount, assignmentWithResourceCount]
  );

  return (
    <Grid container className={classes.root}>
      <Typography
        data-qe-id="ExpandResourceEstimateSection"
        variant="subtitle1"
        className={classes.title}
      >
        <FormattedMessage id="taskResourceEstimates.resources" />
      </Typography>
      {expanded ? (
        <div className={classes.closedExpansionPanel}>
          <Button
            color="primary"
            startIcon={addResourceIcon}
            className={classes.button}
            onClick={onAddResourceClick}
            data-qe-id="AddResourceEstimate"
          >
            <FormattedMessage id="taskResourceEstimates.resource" />
          </Button>
          {assignmentCount > 0 && (
            <ListTableNavigation
              currentPage={currentPage}
              navigatePage={setCurrentPage}
              pageSize={DEFAULT_PAGE_SIZE}
              recordCount={assignmentCount}
              stopPropagation
            />
          )}
        </div>
      ) : loading ? (
        <Skeleton width="25%" height={20} />
      ) : (
        <Typography variant="subtitle2" className={classes.resourceCount}>
          <FormattedMessage
            id="taskResourceEstimates.resourceCount.count"
            values={values}
          />
        </Typography>
      )}
    </Grid>
  );
};

export default TaskEstimateTitle;

TaskEstimateTitle.propTypes = {
  expanded: PropTypes.bool,
  loading: PropTypes.bool,
  onAddResourceClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func
};
