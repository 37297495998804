import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  EditorPopoverContent,
  EditorPopoverSummaryHeader,
  EditorPopoverFooter
} from './components';
import { useFormState as useEditorFormState } from './hooks';

const ProjectManagerRoleEditorContent = ({
  onClose,
  taskStartDate,
  taskEndDate,
  taskId,
  resourceEstimateId,
  userId,
  roleId,
  projectId,
  projectSlug,
  otherTaskAllocationsSummary,
  resourceAllocation,
  initialEstimatedHours,
  taskResourceUserAllocation,
  estimatedAtCompletionHours
}) => {
  const { initialValues, validationSchema, onSubmit } = useEditorFormState({
    taskId,
    taskStartDate,
    taskEndDate,
    userId,
    roleId,
    resourceEstimateId,
    onClose,
    projectId,
    otherTaskAllocationsSummary,
    resourceAllocation,
    initialEstimatedHours,
    taskResourceUserAllocation
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      <>
        <EditorPopoverSummaryHeader
          resourceAllocation={resourceAllocation}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          projectSlug={projectSlug}
        />
        <EditorPopoverContent
          resourceAllocation={resourceAllocation}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          initialEstimatedHours={initialEstimatedHours}
          estimatedAtCompletionHours={estimatedAtCompletionHours}
          taskResourceUserAllocation={taskResourceUserAllocation}
        />
        <EditorPopoverFooter
          onClose={onClose}
          hasTaskResourceUserAllocation={Boolean(taskResourceUserAllocation)}
        />
      </>
    </Formik>
  );
};

ProjectManagerRoleEditorContent.propTypes = {
  resourceAllocation: PropTypes.object,
  onClose: PropTypes.func,
  taskStartDate: PropTypes.object,
  taskEndDate: PropTypes.object,
  taskId: PropTypes.string,
  userId: PropTypes.string,
  roleId: PropTypes.string,
  projectId: PropTypes.string,
  projectSlug: PropTypes.string.isRequired,
  otherTaskAllocationsSummary: PropTypes.object,
  initialEstimatedHours: PropTypes.number,
  taskResourceUserAllocation: PropTypes.object,
  resourceEstimateId: PropTypes.string.isRequired,
  estimatedAtCompletionHours: PropTypes.number
};

export default ProjectManagerRoleEditorContent;
