import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { compact } from 'lodash';
import { ExpenseEntryType } from '~/types';
import { allowedExpensesAndEstimatesColumns } from '~/modules/projects/project/AllowedExpensesAndEstimates/hooks';
import { AllowedExpensesAndEstimatesFooter } from '../AllowedExpensesAndEstimates';
import ExpenseDialog from './ExpenseDialog';

const defaultVisibleColumns = [
  allowedExpensesAndEstimatesColumns.EXPENSE_TYPE,
  allowedExpensesAndEstimatesColumns.BILLABLE_TYPE,
  allowedExpensesAndEstimatesColumns.ESTIMATES,
  allowedExpensesAndEstimatesColumns.TOTAL_ESTIMATES
];

export const ExpenseEditableDialog = ({
  onClose,
  open,
  projectEndDate,
  projectPermissions,
  projectStartDate
}) => {
  const { canEditExpenseCodes, canEditBillingContracts } = projectPermissions;
  const { values, handleSubmit, isSubmitting, resetForm } = useFormikContext();
  const hasAtleastOneBillableRow = useMemo(
    () =>
      values.allowedExpensesAndEstimates.some(
        ({ billableType }) => billableType !== ExpenseEntryType.NonBillable
      ),
    [values.allowedExpensesAndEstimates]
  );

  const visibleColumns = useMemo(
    () =>
      compact([
        ...defaultVisibleColumns,
        canEditBillingContracts &&
          allowedExpensesAndEstimatesColumns.DELETE_ROW,
        hasAtleastOneBillableRow && allowedExpensesAndEstimatesColumns.MARK_UP
      ]),
    [canEditBillingContracts, hasAtleastOneBillableRow]
  );

  const onCancel = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const onSave = useCallback(() => {
    handleSubmit();
    onClose();
  }, [handleSubmit, onClose]);

  const actionComponentCustomProps = useMemo(() => ({ canEditExpenseCodes }), [
    canEditExpenseCodes
  ]);

  const dialogAction = useMemo(
    () => (
      <AllowedExpensesAndEstimatesFooter
        onCancel={onCancel}
        onSave={onSave}
        saveable={!isSubmitting}
        isSaving={isSubmitting}
        actionComponentCustomProps={actionComponentCustomProps}
      />
    ),
    [actionComponentCustomProps, isSubmitting, onCancel, onSave]
  );

  return (
    <ExpenseDialog
      editable
      onClose={onClose}
      open={open}
      projectEndDate={projectEndDate}
      projectStartDate={projectStartDate}
      dialogAction={dialogAction}
      visibleColumns={visibleColumns}
    />
  );
};

ExpenseEditableDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  projectEndDate: PropTypes.string,
  projectPermissions: PropTypes.object,
  projectStartDate: PropTypes.string
};

export default ExpenseEditableDialog;
