/* eslint-disable react/prop-types */

import React, { useContext, useReducer, useMemo, useEffect } from 'react';
import { useDialogState } from '~/modules/common/hooks';
import resourceRequestAllocationsReducer, {
  INITIAL_STATE
} from './resourceRequestAllocationsReducer';

const ResourceAssignmentRequestContext = React.createContext();

export const useResourceAssignmentRequestContext = () =>
  useContext(ResourceAssignmentRequestContext);

export const withResourceAssignmentRequestContextProvider = BaseComponent => props => {
  const {
    resourceRequest,
    chartSettings,
    scoreComputeStatus,
    costScoreComputeStatus
  } = props;

  const [state, dispatch] = useReducer(resourceRequestAllocationsReducer, {
    allocations: []
  });

  useEffect(
    () =>
      dispatch({
        type: INITIAL_STATE,
        allocations: resourceRequest?.resourceAllocations || []
      }),
    [resourceRequest?.resourceAllocations]
  );

  const {
    open: showAcceptResourceDialog,
    openDialog: openAcceptResourceDialog,
    closeDialog: closeAcceptResourceDialog
  } = useDialogState(false);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      showAcceptResourceDialog,
      openAcceptResourceDialog,
      closeAcceptResourceDialog
    }),
    [
      state,
      showAcceptResourceDialog,
      openAcceptResourceDialog,
      closeAcceptResourceDialog
    ]
  );

  return (
    <ResourceAssignmentRequestContext.Provider value={contextValue}>
      <BaseComponent
        resourceRequest={resourceRequest}
        chartSettings={chartSettings}
        scoreComputeStatus={scoreComputeStatus}
        costScoreComputeStatus={costScoreComputeStatus}
      />
    </ResourceAssignmentRequestContext.Provider>
  );
};

export default ResourceAssignmentRequestContext;
