import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { compact } from 'lodash';
import { NoDataItem } from '~/modules/common/components/NoDataItem';
import { ExpenseEntryType } from '~/types';
import {
  AllowedExpensesAndEstimates,
  AllowedExpensesAndEstimatesHeader,
  ErrorMessage
} from '~/modules/projects/project/AllowedExpensesAndEstimates';
import {
  allowedExpensesAndEstimatesColumns,
  getTotalsForDataPoints
} from '~/modules/projects/project/AllowedExpensesAndEstimates/hooks';

export const useNoDataClasses = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'pre'
  }
}));

const defaultVisibleColumns = [
  allowedExpensesAndEstimatesColumns.EXPENSE_TYPE,
  allowedExpensesAndEstimatesColumns.BILLABLE_TYPE,
  allowedExpensesAndEstimatesColumns.ESTIMATES,
  allowedExpensesAndEstimatesColumns.TOTAL_ESTIMATES
];

export const ExpenseBillPlanEditContent = ({
  chartDates,
  dateRange,
  editable,
  onNext,
  onPrevious,
  projectEndDate,
  projectPermissions,
  projectStartDate,
  setDate
}) => {
  const { canEditExpenseCodes, canViewExpenseCodes } = projectPermissions;
  const noDataClasses = useNoDataClasses();
  const {
    values: { allowedExpensesAndEstimates, showActuals }
  } = useFormikContext();

  const hasAtleastOneBillableRow = useMemo(
    () =>
      allowedExpensesAndEstimates.some(
        ({ billableType }) => billableType !== ExpenseEntryType.NonBillable
      ),
    [allowedExpensesAndEstimates]
  );

  const visibleColumns = useMemo(
    () =>
      compact([
        ...defaultVisibleColumns,
        hasAtleastOneBillableRow && allowedExpensesAndEstimatesColumns.MARK_UP,
        canEditExpenseCodes && allowedExpensesAndEstimatesColumns.DELETE_ROW
      ]),
    [canEditExpenseCodes, hasAtleastOneBillableRow]
  );

  const totals = useMemo(
    () =>
      getTotalsForDataPoints(
        allowedExpensesAndEstimates,
        chartDates,
        showActuals
      ),
    [allowedExpensesAndEstimates, chartDates, showActuals]
  );

  if (!canViewExpenseCodes)
    return (
      <ErrorMessage errorKey="expenseBillPlan.noPermissionToEditBillPlan" />
    );

  if (allowedExpensesAndEstimates.length === 0)
    return (
      <NoDataItem classes={noDataClasses}>
        <FormattedMessage id="expenseBillPlan.noExpenseType" />
      </NoDataItem>
    );

  return (
    <>
      <AllowedExpensesAndEstimatesHeader
        dateRange={dateRange}
        editable={editable}
        onNext={onNext}
        onPrevious={onPrevious}
        setDate={setDate}
      />
      <AllowedExpensesAndEstimates
        chartDates={chartDates}
        editable={editable}
        projectEndDate={projectEndDate}
        projectStartDate={projectStartDate}
        totals={totals}
        visibleColumns={visibleColumns}
      />
    </>
  );
};

ExpenseBillPlanEditContent.propTypes = {
  chartDates: PropTypes.array,
  dateRange: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  projectEndDate: PropTypes.string,
  projectPermissions: PropTypes.object,
  projectStartDate: PropTypes.string,
  setDate: PropTypes.func.isRequired
};
