import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import {
  TaskAllocationTimelineBlocks,
  TaskAllocationTimelineOverlayBlocks
} from '~/modules/resourcing/common/components/ResourceRequestUserAllocationBlock';
import {
  TaskAllocationTimelineEditorOverlay,
  ResourceAllocationChangeSnackBar
} from '~/modules/resourcing/common/components';
import {
  useUpdateTaskResourceUserAllocation,
  useCreateTaskResourceUserAllocation
} from '~/modules/common/components/TaskDrawer/common/hooks';
import { useResourceTimelineEditorChangeHandlers } from '~/modules/resourcing/common/hooks';
import { useTaskAssignmentTimelineEditorChangeHandlers } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';
import { useDialogState } from '~/modules/common/hooks';
import { useUserAllocationsSummaryContext } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/components/ResourceAllocationChartRow/UserAllocationsSummaryContext.js';
import { RangeBoundaries } from '~/modules/common/charts/timeline/components';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import ReleaseTaskDialogsContainer from './ReleaseTaskDialogsContainer';

const mapTaskAllocationToAllocationTimeline = ({
  user,
  userScheduleDetails,
  taskResourceUserAllocation = {}
}) => {
  const { displayText, id, uri } = user;
  const { scheduleDurationByPeriodMap } = userScheduleDetails;

  return {
    user: {
      id,
      uri,
      scheduleDurationByPeriodMap,
      displayText,
      user: {
        id,
        uri,
        displayText
      }
    },
    ...taskResourceUserAllocation
  };
};

export const getOverAllocatedDisplayProperties = ({
  userOverDistributedPeriodsMap
}) => ({ periodStart }) => ({
  isOverAllocated:
    userOverDistributedPeriodsMap[periodStart.toISO()]
      ?.periodAvailableTaskAllocationHours < 0
});

export const TaskAssignmentTimeline = ({
  chartDisplayPeriods,
  handleRemoveTaskAssignment,
  user,
  taskResourceEstimate,
  taskResourceUserAllocation,
  userScheduleDetails
}) => {
  const {
    scale,
    chartDates,
    dateRange,
    displayUnit
  } = useResourceRequestToolbarContext();
  const { project } = useProjectContext();
  const { id: projectId, startDate, endDate } = project;
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: ''
  });

  const {
    loadingResourceAllocation,
    resourceAllocationScheduleRules,
    userOverDistributedPeriodsMap,
    userTaskAllocationsSummaryLoading,
    userTaskAllocationsSummaryScheduleRules
  } = useUserAllocationsSummaryContext();

  const updateTaskResourceUserAllocation = useUpdateTaskResourceUserAllocation();
  const createTaskResourceUserAllocation = useCreateTaskResourceUserAllocation();

  const [allocationPeriodEditTarget, setAllocationPeriodEditTarget] = useState(
    null
  );

  const {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  } = useResourceTimelineEditorChangeHandlers({
    allocationPeriodEditTarget,
    chartDates,
    isPercentageMode: displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE,
    setAllocationPeriodEditTarget,
    resourceAllocation: taskResourceUserAllocation || {}
  });

  const {
    open: releaseTaskDialogsContainerOpen,
    openDialog: openReleaseTaskDialogsContainer,
    closeDialog: closeReleaseTaskDialogsContainer
  } = useDialogState(false);

  const {
    onPeriodClose,
    onAllocationChange
  } = useTaskAssignmentTimelineEditorChangeHandlers({
    updateTaskResourceUserAllocation,
    createTaskResourceUserAllocation,
    taskResourceUserAllocation,
    taskResourceEstimate,
    projectId,
    userId: user.id,
    openReleaseTaskDialogsContainer,
    setSnackBarState
  });

  const mappedTaskAllocation = mapTaskAllocationToAllocationTimeline({
    user,
    userScheduleDetails,
    taskResourceUserAllocation
  });

  return (
    <>
      <RangeBoundaries
        chartStartDate={dateRange.startDate}
        scale={scale}
        start={startDate}
        end={endDate}
      />
      {taskResourceUserAllocation ? (
        <TaskAllocationTimelineBlocks
          taskAllocation={mappedTaskAllocation}
          chartDisplayPeriods={chartDisplayPeriods}
          scale={scale}
          isEditable
          chartDisplayDateRange={dateRange}
          onAllocationChange={onAllocationChange}
          handleAllocationPeriodClick={handleAllocationPeriodClick}
          getCustomDisplayPeriodProps={getOverAllocatedDisplayProperties({
            userOverDistributedPeriodsMap
          })}
        />
      ) : (
        <TaskAllocationTimelineOverlayBlocks
          chartDisplayPeriods={chartDisplayPeriods}
          scale={scale}
          isEditable
          allocation={mappedTaskAllocation}
          handleAllocationPeriodClick={handleAllocationPeriodClick}
        />
      )}
      {allocationPeriodEditTarget?.anchorEl && (
        <TaskAllocationTimelineEditorOverlay
          chartDisplayDateRange={dateRange}
          scale={scale}
          loadingResourceAllocation={loadingResourceAllocation}
          resourceAllocationScheduleRules={resourceAllocationScheduleRules}
          userTaskAllocationsSummaryLoading={userTaskAllocationsSummaryLoading}
          userTaskAllocationsSummaryScheduleRules={
            userTaskAllocationsSummaryScheduleRules
          }
          allocationPeriodEditTarget={allocationPeriodEditTarget}
          onChange={onPeriodClose}
          handleAllocationPeriodClose={handleAllocationPeriodClose}
          taskResourceUserAllocation={mappedTaskAllocation}
          setNextPeriod={setNextPeriod}
          setPreviousPeriod={setPreviousPeriod}
          userId={user.id}
        />
      )}
      {releaseTaskDialogsContainerOpen && (
        <ReleaseTaskDialogsContainer
          projectId={projectId}
          task={taskResourceEstimate.task}
          user={user}
          closeReleaseTaskDialogsContainer={closeReleaseTaskDialogsContainer}
          handleRemoveTaskAssignment={handleRemoveTaskAssignment}
        />
      )}
      {snackBarState.open && (
        <ResourceAllocationChangeSnackBar
          snackBarState={snackBarState}
          setSnackBarState={setSnackBarState}
        />
      )}
    </>
  );
};

TaskAssignmentTimeline.propTypes = {
  taskResourceUserAllocation: PropTypes.object,
  user: PropTypes.object,
  userScheduleDetails: PropTypes.object,
  chartDisplayPeriods: PropTypes.array.isRequired,
  taskResourceEstimate: PropTypes.object,
  handleRemoveTaskAssignment: PropTypes.func
};

export default TaskAssignmentTimeline;
