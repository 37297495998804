import React, { useMemo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Decimal } from '~/modules/common/components';
import { FieldLabel } from '../../common/components';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    cursor: 'help',
    color: theme.palette.text.secondary
  },
  container: {
    display: 'flex'
  },
  hours: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightMedium
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const AvailabilityInfoComponent = ({ hours }) => {
  const classes = useStyles();

  const tooltipRemainingValue = useMemo(
    () => ({
      hours: (
        <div className={classNames(classes.label, classes.container)}>
          <Decimal className={classes.label} value={hours} />
          <div className={classes.hours}>
            <FormattedMessage id="taskAllocationEditor.hoursSuffix" />
          </div>
        </div>
      )
    }),
    [classes.container, classes.hours, classes.label, hours]
  );

  return (
    <div className={classes.container}>
      <FieldLabel
        variant="body2"
        color="textSecondary"
        messageId="taskAllocationEditor.remainingProject"
        messageValues={tooltipRemainingValue}
        className={classes.container}
      />
      <Tooltip title={<></>} placement="right">
        <InfoIcon className={classes.infoIcon} fontSize="small" />
      </Tooltip>
    </div>
  );
};

AvailabilityInfoComponent.propTypes = {
  hours: PropTypes.number.isRequired
};

export default AvailabilityInfoComponent;
