import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Button } from '@material-ui/core';
import { useProjectLinkProjectsContext } from '~/modules/projects/project/ProjectLinksTab/ProjectLinkProjectsContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    maxWidth: '100%'
  }
}));

export const AddProjectLink = () => {
  const classes = useStyles();
  const { edit } = useProjectLinkProjectsContext();

  return (
    <div className={classes.container}>
      <Button disabled={!edit} variant="text" color="primary">
        <FormattedMessage id="projectLink.addLink" />
      </Button>
    </div>
  );
};

AddProjectLink.propTypes = {};

export default AddProjectLink;
