import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { MoneyValue } from '~/modules/common/components/Money';
import {
  useUpdateProjectTimeAndExpense,
  useInitialValues,
  useOnSubmit,
  useExpenseActualsAndEstimates,
  useExpenseCardSettings,
  usePutExpenseCardSettings,
  useBulkPutEstimatedExpenses,
  wellKnownScriptParameters
} from '~/modules/projects/project/AllowedExpensesAndEstimates/hooks';

import { ExpenseBillPlanEditableCard } from './ExpenseBillPlanEditableCard';

const getExpenseCodesFromExpenseScript = scripts =>
  scripts.map(s => ({
    ...s[wellKnownScriptParameters.expenseType],
    isExpenseEntryToThisCodeAllowed: true
  }));

export const ExpenseBillPlan = ({
  editable,
  endDate: projectEndDate,
  expenseCodes,
  isBillingContractType,
  onCancelCallback,
  onDeleteScript,
  onSave: onBillPlanSave,
  projectCurrency,
  projectId,
  projectPermissions,
  recalculate,
  scriptDetails,
  startDate: projectStartDate
}) => {
  const { canViewExpenseCodes } = projectPermissions;
  const { bulkPutEstimatedExpenses } = useBulkPutEstimatedExpenses();
  const { putExpenseCardSettings } = usePutExpenseCardSettings({ projectId });
  const updateProjectExpenses = useUpdateProjectTimeAndExpense({
    projectUri: projectId,
    includeExpenseCodes: false
  });

  const {
    expenseCardSettings,
    loading: expenseCardSettingsLoading
  } = useExpenseCardSettings({ projectId });

  const showActuals = Boolean(expenseCardSettings?.showActuals);

  const {
    estimatedExpensesSeries,
    estimatedExpensesSummary,
    expenseActualsSeries,
    expenseActualsSummary
  } = useExpenseActualsAndEstimates({
    projectId,
    currencyId: projectCurrency?.id,
    endDate: projectEndDate,
    startDate: projectStartDate,
    skipActuals: !editable || expenseCardSettingsLoading || !showActuals,
    skipEstimates: !editable
  });

  const initialValues = useInitialValues({
    estimatedExpensesSeries,
    estimatedExpensesSummary,
    expenseActualsSeries,
    expenseActualsSummary,
    expenseCodes: canViewExpenseCodes
      ? expenseCodes
      : getExpenseCodesFromExpenseScript(scriptDetails.scripts),
    projectCurrency,
    scriptDetails,
    showActuals
  });

  const onSubmit = useOnSubmit({
    bulkPutEstimatedExpenses,
    projectPermissions,
    isBillingContractType,
    onBillPlanSave,
    planId: scriptDetails.id,
    projectEndDate,
    projectId,
    putExpenseCardSettings,
    recalculate,
    savedShowActuals: showActuals,
    updateProjectExpenses
  });

  const onDeleteClick = useCallback(async () => {
    await bulkPutEstimatedExpenses(projectId, []);
    await updateProjectExpenses({ expenseCodes: [] });
    await onDeleteScript(scriptDetails.id)();
  }, [
    bulkPutEstimatedExpenses,
    onDeleteScript,
    projectId,
    scriptDetails.id,
    updateProjectExpenses
  ]);

  const subTitle = useMemo(
    () =>
      scriptDetails.estimatedAmount ? (
        <>
          <FormattedMessage id="expenseBillPlan.estimatedAmount" />
          <MoneyValue money={scriptDetails.estimatedAmount} />
        </>
      ) : null,
    [scriptDetails.estimatedAmount]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <ExpenseBillPlanEditableCard
        editable={editable}
        estimatedAmount={scriptDetails.estimatedAmount}
        onCancelCallback={onCancelCallback}
        onDeleteClick={onDeleteClick}
        projectEndDate={projectEndDate}
        projectPermissions={projectPermissions}
        projectStartDate={projectStartDate}
        subTitle={subTitle}
      />
    </Formik>
  );
};

ExpenseBillPlan.propTypes = {
  editable: PropTypes.bool,
  endDate: PropTypes.string,
  expenseCodes: PropTypes.array,
  isBillingContractType: PropTypes.bool,
  onCancelCallback: PropTypes.func,
  onDeleteScript: PropTypes.func,
  onSave: PropTypes.func,
  projectCurrency: PropTypes.object,
  projectId: PropTypes.string,
  projectPermissions: PropTypes.object,
  recalculate: PropTypes.func,
  scriptDetails: PropTypes.object,
  startDate: PropTypes.string
};

export default ExpenseBillPlan;
