import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const PROPOSE_TOP_MATCHES = gql`
  mutation proposeTopMatches(
    $projectUri: String!
    $resourceRequestUris: [String!]!
  ) {
    proposeTopMatches(
      projectUri: $projectUri
      resourceRequestUris: $resourceRequestUris
    )
  }
`;

export const useProposeTopMatches = () => {
  const [proposeTopMatches] = useMutation(PROPOSE_TOP_MATCHES);

  return {
    proposeTopMatches: ({ projectUri, resourceRequestUris }) =>
      proposeTopMatches({
        variables: {
          projectUri,
          resourceRequestUris
        },
        refetchQueries: [
          'resourceRequests',
          'resourcePlansSummaryForCurrentTenant'
        ]
      })
  };
};

export default useProposeTopMatches;
