import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ChangeTaskResourceDialogTitle } from './ChangeTaskResourceDialogTitle';
import { ChangeTaskResourceDialogContent } from './ChangeTaskResourceDialogContent';
import { ChangeTaskResourceDialogFooter } from './ChangeTaskResourceDialogFooter';

export const ChangeTaskResourceDialog = ({
  onClose,
  open,
  resourceEstimate,
  task
}) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <ChangeTaskResourceDialogTitle
        taskName={task.displayText}
        isParentTask={task.hasChildren}
      />
      <ChangeTaskResourceDialogContent resourceEstimate={resourceEstimate} />
      <ChangeTaskResourceDialogFooter onClose={onClose} />
    </Dialog>
  );
};

ChangeTaskResourceDialog.propTypes = {
  task: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  resourceEstimate: PropTypes.object
};

export default ChangeTaskResourceDialog;
