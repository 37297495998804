import React from 'react';
import PropTypes from 'prop-types';
import EditorPopoverFooterLayout from '../../common/components/PopoverFooterLayout';

const noop = () => {};
const EditorPopoverFooter = ({ onClose }) => (
  <EditorPopoverFooterLayout
    onClose={onClose}
    showMoreMenu
    onMenuClick={noop}
    submitForm={noop}
    isLoading={false}
    isDisabled={false}
  />
);

EditorPopoverFooter.propTypes = {
  onClose: PropTypes.func
};

export default EditorPopoverFooter;
