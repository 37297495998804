import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, makeStyles, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hasError } from '~/util';
import {
  NoValue,
  DateField,
  Decimal,
  SummaryFormattedNumber,
  Hours
} from '~/modules/common/components';
import { FieldLabel } from '.';

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  title: {
    ...theme.typography.subtitle1,
    paddingTop: theme.spacing(2)
  },
  inputAdornment: {
    paddingLeft: theme.spacing(0.5),
    margin: 0
  },
  taskAllocationGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  error: {
    color: theme.palette.error.main
  },
  diff: {
    display: 'flex',
    marginTop: theme.spacing(1),
    alignItems: 'center'
  },
  taskAllocation: {
    display: 'flex',
    justifyContent: 'end'
  },
  hoursLabel: {
    textAlign: 'right',
    paddingRight: '14px'
  },
  label: {
    color: theme.palette.text.primary
  },
  selectDatesPrompt: {
    marginLeft: theme.spacing(2),
    minWidth: theme.spacing(20)
  },
  noValue: {
    display: 'flex',
    paddingRight: theme.spacing(1.75),
    justifyContent: 'end'
  }
}));

const useAllocatedHoursStyles = makeStyles(theme => ({
  input: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const startDateLabel = <FormattedMessage id="taskAllocationEditor.startDate" />;
const endDateLabel = <FormattedMessage id="taskAllocationEditor.endDate" />;

const useSummaryFormattedNumberStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    fontWeight: 'bold'
  },
  diffPositive: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

const PopoverContentLayout = ({
  startDate,
  endDate,
  dateRangePickerProps: {
    onStartDateChange,
    onEndDateChange,
    errors,
    shouldDisableDateFunc
  },
  onHoursChange,
  availabilityField: {
    labelComponent: availabilityLabel,
    hoursValue: availableHours,
    infoComponent: availabilityInfoComponent
  },
  allocationField: {
    labelComponent: allocationLabel,
    hoursValue: allocationHours
  },
  remainingField: {
    labelComponent: remainingLabel,
    hoursValue: remainingHours
  },
  varianceField: {
    labelComponent: allocationVarianceLabel,
    hoursValue: allocationVariance
  }
}) => {
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const summaryFormattedNumberClasses = useSummaryFormattedNumberStyles();
  const allocatedHoursClasses = useAllocatedHoursStyles();

  const endAdornment = useMemo(
    () => (
      <InputAdornment className={classes.inputAdornment}>
        <FieldLabel
          messageId="taskAllocationEditor.hoursSuffix"
          variant="body2"
          color="textSecondary"
        />
      </InputAdornment>
    ),
    [classes.inputAdornment]
  );

  const availableHoursLabelValue = useMemo(
    () => ({
      hours: <Decimal className={classes.label} value={availableHours} />
    }),
    [availableHours, classes.label]
  );

  const remainingHoursLabelValue = useMemo(
    () => ({
      hours: (
        <Decimal
          value={remainingHours}
          className={classNames({
            [classes.error]: remainingHours < 0,
            [classes.label]: remainingHours >= 0
          })}
        />
      )
    }),
    [classes.error, classes.label, remainingHours]
  );

  return (
    <>
      <FieldLabel
        messageId="taskAllocationEditor.taskAllocation"
        className={classes.title}
      />
      <Grid>
        <Grid item container spacing={2} className={classes.datePicker}>
          <Grid item xs={12} sm={6}>
            <DateField
              clearable={false}
              variant="outlined"
              value={startDate}
              editable
              ariaLabel={startDateLabel}
              onChange={onStartDateChange}
              label={startDateLabel}
              error={hasError(errors, 'startDate')}
              shouldDisableDate={shouldDisableDateFunc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateField
              clearable={false}
              variant="outlined"
              value={endDate}
              editable
              onChange={onEndDateChange}
              ariaLabel={endDateLabel}
              label={endDateLabel}
              error={hasError(errors, 'endDate')}
              shouldDisableDate={shouldDisableDateFunc}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3}>
            {availabilityLabel}
          </Grid>
          <Grid item xs={12} sm={3}>
            {startDate && endDate ? (
              <FieldLabel
                messageId="taskAllocationEditor.hours"
                messageValues={availableHoursLabelValue}
                variant="body2"
                className={classes.hoursLabel}
                color="textSecondary"
              />
            ) : (
              <FieldLabel
                messageId="taskAllocationEditor.selectDatesPrompt"
                variant="caption"
                color="textSecondary"
                className={classes.selectDatesPrompt}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {availabilityInfoComponent}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3} className={classes.taskAllocationGrid}>
            {allocationLabel}
          </Grid>
          <Grid item xs={12} sm={3} className={classes.taskAllocation}>
            <Hours
              classes={allocatedHoursClasses}
              autoFocus
              value={allocationHours}
              variant="outlined"
              onChange={onHoursChange}
              endAdornment={endAdornment}
              error={hasError(errors, 'allocatedHours')}
              ariaLabel={formatMessage({
                id: 'taskAllocationEditor.taskAllocation'
              })}
              align="right"
              isEditible
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {Boolean(allocationVariance) && (
              <div className={classes.diff}>
                <SummaryFormattedNumber
                  classes={summaryFormattedNumberClasses}
                  value={allocationVariance}
                  formattedMessageKey="taskAllocationEditor.diffHours"
                  showAddIcon={allocationVariance > 0}
                  isHigher={allocationVariance > 0}
                />
                {allocationVarianceLabel}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3}>
            {remainingLabel}
          </Grid>
          <Grid item xs={12} sm={3}>
            {startDate && endDate ? (
              <FieldLabel
                messageId="taskAllocationEditor.hours"
                variant="body2"
                color="textSecondary"
                className={classNames(classes.hoursLabel, {
                  [classes.error]: remainingHours < 0
                })}
                messageValues={remainingHoursLabelValue}
              />
            ) : (
              <div className={classes.noValue}>
                <NoValue />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

PopoverContentLayout.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  dateRangePickerProps: PropTypes.object,
  availabilityField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired,
    infoComponent: PropTypes.node
  }).isRequired,
  allocationField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired
  }).isRequired,
  remainingField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired
  }).isRequired,
  onHoursChange: PropTypes.func,
  varianceField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired
  })
};

export default PopoverContentLayout;
