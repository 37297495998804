import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { compact } from 'lodash';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ExpenseEntryType } from '~/types';
import { allowedExpensesAndEstimatesColumns } from '~/modules/projects/project/AllowedExpensesAndEstimates/hooks';
import ExpenseDialog from './ExpenseDialog';

const useColumnStyles = makeStyles(theme => ({
  totalEstimates: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    minWidth: theme.spacing(22),
    right: theme.spacing(0),
    zIndex: 1,
    backgroundColor: theme.palette.grey[50]
  }
}));

const defaultVisibleColumns = [
  allowedExpensesAndEstimatesColumns.EXPENSE_TYPE,
  allowedExpensesAndEstimatesColumns.BILLABLE_TYPE,
  allowedExpensesAndEstimatesColumns.ESTIMATES,
  allowedExpensesAndEstimatesColumns.TOTAL_ESTIMATES
];

export const ExpenseReadOnlyDialog = ({
  onClose,
  open,
  projectEndDate,
  projectStartDate
}) => {
  const columnClasses = useColumnStyles();
  const { values } = useFormikContext();
  const hasAtleastOneBillableRow = useMemo(
    () =>
      values.allowedExpensesAndEstimates.some(
        ({ billableType }) => billableType !== ExpenseEntryType.NonBillable
      ),
    [values.allowedExpensesAndEstimates]
  );

  const visibleColumns = useMemo(
    () =>
      compact([
        ...defaultVisibleColumns,
        hasAtleastOneBillableRow && allowedExpensesAndEstimatesColumns.MARK_UP
      ]),
    [hasAtleastOneBillableRow]
  );

  const dialogAction = useMemo(
    () => (
      <Grid container spacing={0} justifyContent="flex-end">
        <Button onClick={onClose}>
          <FormattedMessage id="button.close" />
        </Button>
      </Grid>
    ),
    [onClose]
  );

  return (
    <ExpenseDialog
      columnClasses={columnClasses}
      editable={false}
      onClose={onClose}
      open={open}
      projectEndDate={projectEndDate}
      projectStartDate={projectStartDate}
      dialogAction={dialogAction}
      visibleColumns={visibleColumns}
    />
  );
};

ExpenseReadOnlyDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  projectEndDate: PropTypes.string,
  projectStartDate: PropTypes.string
};

export default ExpenseReadOnlyDialog;
