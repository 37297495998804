import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { useFormChangeHandlers } from '../hooks';
import PopoverContentLayout from '../../common/components/PopoverContentLayout';
import { FieldLabel } from '../../common/components';
import AvailabilityInfoComponent from './AvailabilityInfoComponent';

const useStyles = makeStyles(theme => ({
  projectLabel: {
    color: theme.palette.text.secondary
  }
}));

export const getAllocationVariance = ({ estimatedHours, allocatedHours }) =>
  estimatedHours ? allocatedHours - estimatedHours : null;

export const getVarianceMessageId = ({
  allocationVariance,
  taskResourceUserAllocationId
}) => {
  if (taskResourceUserAllocationId) {
    return allocationVariance > 0
      ? 'taskAllocationEditor.aboveEstimatedAtCompletion'
      : 'taskAllocationEditor.belowEstimatedAtCompletion';
  }

  return allocationVariance > 0
    ? 'taskAllocationEditor.aboveTaskEstimate'
    : 'taskAllocationEditor.belowTaskEstimate';
};

export const getDisabledDatesFromResourceAllocation = ({
  resourceAllocation
}) => date => {
  const { startDate, endDate } = resourceAllocation;
  const allocationStartDate = mapIsoStringtoUtcObject(startDate);
  const allocationEndDate = mapIsoStringtoUtcObject(endDate);
  const currentDay = mapRepliconDateToUtcObject(date);

  return currentDay > allocationEndDate || currentDay < allocationStartDate;
};

const EditorPopoverContent = ({
  resourceAllocation,
  otherTaskAllocationsSummary,
  initialEstimatedHours,
  estimatedAtCompletionHours,
  taskResourceUserAllocation
}) => {
  const { scheduleRules: resourceAllocationScheduleRules } = resourceAllocation;
  const {
    scheduleRules: otherTaskAllocationsSummaryScheduleRules
  } = otherTaskAllocationsSummary;
  const { values, errors, setValues } = useFormikContext();

  const { startDate, endDate, projectAvailableHours, allocatedHours } = values;
  const roundedProjectAvailableHours = roundToDecimals(projectAvailableHours);
  const roundedAllocatedHours = roundToDecimals(allocatedHours);

  const remainingHours = roundedProjectAvailableHours - roundedAllocatedHours;

  const allocationVariance = getAllocationVariance({
    estimatedHours: taskResourceUserAllocation?.id
      ? estimatedAtCompletionHours
      : initialEstimatedHours,
    allocatedHours
  });

  const {
    onStartDateChange,
    onEndDateChange,
    onTaskAllocationHoursChange
  } = useFormChangeHandlers({
    values,
    setValues,
    startDate,
    endDate,
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules,
    initialEstimatedHours
  });

  const classes = useStyles();

  const shouldDisableDateFunc = getDisabledDatesFromResourceAllocation({
    resourceAllocation
  });

  const availableLabel = useMemo(
    () => ({
      project: (
        <span className={classes.projectLabel}>
          <FormattedMessage id="taskAllocationEditor.project" />
        </span>
      )
    }),
    [classes.projectLabel]
  );

  const availabilityField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.availableProject"
          messageValues={availableLabel}
        />
      ),
      hoursValue: roundedProjectAvailableHours,
      infoComponent: startDate && endDate && (
        <AvailabilityInfoComponent
          startDate={startDate}
          endDate={endDate}
          otherTaskAllocationsSummaryScheduleRules={
            otherTaskAllocationsSummaryScheduleRules
          }
          projectAvailableHours={projectAvailableHours}
          resourceAllocationScheduleRules={resourceAllocationScheduleRules}
        />
      )
    }),
    [
      availableLabel,
      endDate,
      otherTaskAllocationsSummaryScheduleRules,
      projectAvailableHours,
      resourceAllocationScheduleRules,
      roundedProjectAvailableHours,
      startDate
    ]
  );

  const remainingField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.remaining"
        />
      ),
      hoursValue: remainingHours
    }),
    [remainingHours]
  );

  const allocationField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.taskAllocation"
        />
      ),
      hoursValue: roundedAllocatedHours
    }),
    [roundedAllocatedHours]
  );

  const varianceField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          messageId={getVarianceMessageId({
            allocationVariance,
            taskResourceUserAllocationId: taskResourceUserAllocation?.id
          })}
          color="textSecondary"
          variant="body2"
        />
      ),
      hoursValue: allocationVariance
    }),
    [allocationVariance, taskResourceUserAllocation?.id]
  );

  const dateRangePickerProps = useMemo(
    () => ({
      onStartDateChange,
      onEndDateChange,
      errors,
      shouldDisableDateFunc
    }),
    [errors, onEndDateChange, onStartDateChange, shouldDisableDateFunc]
  );

  return (
    <PopoverContentLayout
      startDate={startDate}
      endDate={endDate}
      onHoursChange={onTaskAllocationHoursChange}
      dateRangePickerProps={dateRangePickerProps}
      availabilityField={availabilityField}
      remainingField={remainingField}
      allocationField={allocationField}
      varianceField={varianceField}
    />
  );
};

EditorPopoverContent.propTypes = {
  resourceAllocation: PropTypes.object,
  otherTaskAllocationsSummary: PropTypes.object,
  initialEstimatedHours: PropTypes.number,
  estimatedAtCompletionHours: PropTypes.number,
  taskResourceUserAllocation: PropTypes.object
};

export default EditorPopoverContent;
