import React, { useMemo } from 'react';
import { Grid, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Decimal } from '~/modules/common/components';
import { FieldLabel } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor';
import { isoStringToFormattedISOString } from '~/modules/common/dates/convert';
import { ChangeTaskResourceConfirmationArea } from './components/ChangeTaskResourceConfirmationArea';

const dateFormatString = 'LLL dd, yyyy';

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.text.secondary
  },
  hours: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium
  },
  dateRange: {
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(0.375)
  },
  container: {
    backgroundColor: theme.palette.grey[50]
  }
}));

export const ChangeTaskResourceDialogContent = ({ resourceEstimate }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const hoursLabel = formatMessage({
    id: 'changeTaskResourceDialog.hoursSuffix'
  });
  const {
    initialEstimatedHours,
    projectRole,
    taskResourceUserAllocation,
    resourceUser
  } = resourceEstimate;

  const { totalHours, startDate, endDate } = taskResourceUserAllocation || {};

  const taskAllocationDateRange = useMemo(
    () => ({
      startDate: isoStringToFormattedISOString(startDate, dateFormatString),
      endDate: isoStringToFormattedISOString(endDate, dateFormatString)
    }),
    [endDate, startDate]
  );

  return (
    <DialogContent>
      <Grid container className={classes.container}>
        <Grid item md={4}>
          <FieldLabel
            messageId="changeTaskResourceDialog.role"
            variant="caption"
            className={classes.header}
          />
          {projectRole ? (
            <Typography>{projectRole?.displayText}</Typography>
          ) : (
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="changeTaskResourceDialog.noRole" />
            </Typography>
          )}
        </Grid>
        <Grid item md={4}>
          <FieldLabel
            messageId="changeTaskResourceDialog.estimatedHours"
            variant="caption"
            className={classes.header}
          />
          <Typography>
            <Decimal value={initialEstimatedHours} suffix={hoursLabel} />
          </Typography>
        </Grid>
        <Grid item md={4}>
          <FieldLabel
            messageId="changeTaskResourceDialog.taskAllocation"
            variant="caption"
            className={classes.header}
          />
          {taskResourceUserAllocation ? (
            <>
              <Decimal value={totalHours} suffix={hoursLabel} />
              <div className={classes.dateRange}>
                <FieldLabel
                  messageId="changeTaskResourceDialog.taskAllocationDateRange"
                  messageValues={taskAllocationDateRange}
                />
              </div>
            </>
          ) : (
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="changeTaskResourceDialog.noAllocation" />
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container>
        <ChangeTaskResourceConfirmationArea
          existingResource={resourceUser}
          taskResourceUserAllocation={taskResourceUserAllocation}
          taskAllocationDateRange={taskAllocationDateRange}
        />
      </Grid>
    </DialogContent>
  );
};

ChangeTaskResourceDialogContent.propTypes = {
  resourceEstimate: PropTypes.object
};

export default ChangeTaskResourceDialogContent;
