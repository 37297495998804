import React, { useState, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { v4 } from 'uuid';
import { orderBy } from 'lodash';
import { LoadingButton } from '~/modules/common/components';
import { ExpenseEntryType } from '~/types';
import { ExpenseCodeMenu } from './ExpenseCodeMenu';
import { ApprovalStatusDropdown } from './ApprovalStatusDropdown';

const useStyles = makeStyles(theme => ({
  approvalStatusContainer: {
    width: theme.spacing(25),
    margin: theme.spacing(1, 2, 1)
  }
}));

export const AllowedExpensesAndEstimatesFooter = ({
  actionComponentCustomProps,
  onCancel,
  onRemove,
  onSave,
  saveable,
  isSaving
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const {
    canEditExpenseCodes,
    canEditBillingContracts
  } = actionComponentCustomProps;

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onAddExpenseTypeButtonClick = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const { setFieldValue, values } = useFormikContext();

  const onMenuItemClick = useCallback(
    expenseCode => {
      setFieldValue(
        'availableExpenseCodes',
        values.availableExpenseCodes.filter(e => e.id !== expenseCode.id)
      );

      setFieldValue('allowedExpensesAndEstimates', [
        ...values.allowedExpensesAndEstimates,
        {
          id: expenseCode.id,
          scriptId: v4(),
          expenseType: expenseCode,
          markUp: 1,
          billableType: ExpenseEntryType.NonBillable
        }
      ]);
    },
    [
      setFieldValue,
      values.allowedExpensesAndEstimates,
      values.availableExpenseCodes
    ]
  );

  const onApprovalStatusChange = useCallback(
    ({ id: approvalStatus }) => {
      setFieldValue('approvalStatus', approvalStatus);
    },
    [setFieldValue]
  );

  const hasAtleastOneBillableRow = useMemo(
    () =>
      values.allowedExpensesAndEstimates.some(
        ({ billableType }) => billableType !== ExpenseEntryType.NonBillable
      ),
    [values.allowedExpensesAndEstimates]
  );

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          {canEditExpenseCodes && canEditBillingContracts ? (
            <Button
              color="primary"
              onClick={onAddExpenseTypeButtonClick}
              data-qe-id="add"
              disabled={!values.availableExpenseCodes.length}
            >
              <FormattedMessage id="allowedExpensesAndEstimates.addExpenseType" />
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          {hasAtleastOneBillableRow && (
            <Grid container spacing={0} justifyContent="flex-end">
              <div className={classes.approvalStatusContainer}>
                <ApprovalStatusDropdown
                  value={values.approvalStatus}
                  onChange={onApprovalStatusChange}
                />
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          {canEditExpenseCodes && onRemove ? (
            <Button color="secondary" onClick={onRemove}>
              <FormattedMessage id="allowedExpensesAndEstimates.remove" />
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={0} justifyContent="flex-end">
            <Button onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
            <LoadingButton
              color="primary"
              onClick={onSave}
              disabled={!saveable || isSaving}
              isLoading={isSaving}
            >
              <FormattedMessage id="button.save" />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <ExpenseCodeMenu
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        anchorEl={anchorEl}
        onMenuItemClick={onMenuItemClick}
        availableExpenseCodes={orderBy(
          values.availableExpenseCodes,
          'displayText',
          'asc'
        )}
      />
    </>
  );
};
AllowedExpensesAndEstimatesFooter.propTypes = {
  actionComponentCustomProps: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  saveable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool
};

export default AllowedExpensesAndEstimatesFooter;
