import React from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '~/modules/common/components/LoadingButton';

export const ChangeTaskResourceDialogFooter = ({ onClose }) => {
  return (
    <DialogActions>
      <Button onClick={onClose}>
        <FormattedMessage id="changeTaskResourceDialog.cancel" />
      </Button>
      <LoadingButton color="primary">
        <FormattedMessage id="changeTaskResourceDialog.changeTaskResource" />
      </LoadingButton>
    </DialogActions>
  );
};

ChangeTaskResourceDialogFooter.propTypes = {
  onClose: PropTypes.func
};

export default ChangeTaskResourceDialogFooter;
