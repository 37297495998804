import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PopoverContentLayout from '../../common/components/PopoverContentLayout';
import { FieldLabel } from '../../common/components';
import AvailabilityInfoComponent from './AvailabilityInfoComponent';

const hours = 100;
const noop = () => {};

const dateRangePickerProps = {
  onStartDateChange: noop,
  onEndDateChange: noop,
  errors: [],
  shouldDisableDateFunc: noop
};

const remainingField = {
  labelComponent: (
    <FieldLabel variant="body2" messageId="taskAllocationEditor.remaining" />
  ),
  hoursValue: hours
};

const varianceField = {
  labelComponent: (
    <FieldLabel
      messageId="taskAllocationEditor.aboveTaskEstimate"
      color="textSecondary"
      variant="body2"
    />
  ),
  hoursValue: hours
};

const allocationField = {
  labelComponent: (
    <FieldLabel
      variant="body2"
      messageId="taskAllocationEditor.taskAllocation"
    />
  ),
  hoursValue: hours
};
const useStyles = makeStyles(() => ({
  label: {
    width: '120%'
  }
}));
const date = {
  day: 1,
  month: 1,
  year: 2024
};
const EditorPopoverContent = () => {
  const classes = useStyles();

  const availabilityField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.resourceAvailability"
          className={classes.label}
        />
      ),
      hoursValue: hours,
      infoComponent: <AvailabilityInfoComponent hours={hours} />
    }),
    [classes.label]
  );

  return (
    <PopoverContentLayout
      startDate={date}
      endDate={date}
      onHoursChange={noop}
      dateRangePickerProps={dateRangePickerProps}
      availabilityField={availabilityField}
      remainingField={remainingField}
      allocationField={allocationField}
      varianceField={varianceField}
    />
  );
};

EditorPopoverContent.propTypes = {};

export default EditorPopoverContent;
