import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { DialogContentText, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { ExpenseEntryType } from '~/types';
import { AllowedExpensesAndEstimatesFooter } from '~/modules/projects/project/AllowedExpensesAndEstimates';
import { useFixedRange } from '~/modules/common/charts/timeline/calculations';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import { ExpenseBillPlanEditContent } from './ExpenseBillPlanEditContent';
import { ExpenseBillPlanReadOnlyContent } from './ExpenseBillPlanReadOnlyContent';

const useStyles = makeStyles(theme => ({
  editContent: {
    paddingTop: 'unset',
    paddingBottom: 'unset'
  },
  card: {
    paddingBottom: 0
  }
}));

const useDialogActionsStyles = makeStyles(theme => ({
  dialogActions: { marginLeft: 'unset', marginRight: 'unset' }
}));

const removeDialogResourceKeys = {
  actionButton: 'expenseBillPlan.removeDialog.actionButton',
  header: 'expenseBillPlan.removeDialog.header'
};

const getPeriodCount = showActuals => breakPoint =>
  ({
    xs: 1,
    sm: 1,
    md: showActuals ? 3 : 4,
    lg: showActuals ? 3 : 5
  }[breakPoint] || 5);

export const ExpenseBillPlanEditableCard = ({
  editable,
  estimatedAmount,
  onCancelCallback,
  onDeleteClick,
  projectEndDate,
  projectPermissions,
  projectStartDate,
  subTitle
}) => {
  const { formatMessage } = useIntl();
  const { canEditExpenseCodes, canViewExpenseCodes } = projectPermissions;
  const classes = useStyles();
  const dialogActionClasses = useDialogActionsStyles();
  const {
    open: removeDialogOpen,
    closeDialog: closeRemoveDialog,
    openDialog: openRemoveDialog
  } = useDialogState(false);

  const { resetForm, handleSubmit, values, isSubmitting } = useFormikContext();

  const {
    chartDates,
    onPrevious,
    onNext,
    dateRange,
    setDate,
    resetDate
  } = useFixedRange({
    anchor: 0,
    centerOffset: true,
    defaults: { scale: PERIOD_SCALE_ENUM.MONTHS },
    getPeriodCount: getPeriodCount(values.showActuals),
    isPast: true
  });

  const onCancel = useCallback(() => {
    resetForm();
    resetDate();
    onCancelCallback && onCancelCallback();
  }, [resetForm, resetDate, onCancelCallback]);

  const hasExpenseBillPlans = useMemo(
    () =>
      values.allowedExpensesAndEstimates.some(
        ({ billableType }) => billableType !== ExpenseEntryType.NonBillable
      ),
    [values.allowedExpensesAndEstimates]
  );

  const maxWidth = useMemo(
    () => (values.allowedExpensesAndEstimates.length ? 'xl' : 'sm'),
    [values.allowedExpensesAndEstimates.length]
  );

  const actionComponentCustomProps = useMemo(() => ({ canEditExpenseCodes }), [
    canEditExpenseCodes
  ]);

  return (
    <>
      <EditableCard
        classes={useMemo(
          () => ({
            card: classes.card
          }),
          [classes.card]
        )}
        editable={editable && canEditExpenseCodes}
        ariaLabelKey="expenseBillPlan.expenseBillPlanCardDialog"
        edit={values.edit}
        maxWidth={maxWidth}
        editContentClassName={classes.editContent}
        dialogActionClasses={dialogActionClasses}
      >
        {hasExpenseBillPlans && (
          <ReadOnly
            title={formatMessage({ id: 'expenseBillPlan.readOnly.title' })}
            subTitle={subTitle}
            expandable
          >
            <ReadOnlyContent>
              <ExpenseBillPlanReadOnlyContent
                totalEstimatedAmount={estimatedAmount}
              />
            </ReadOnlyContent>
          </ReadOnly>
        )}
        <Edit
          onCancel={onCancel}
          onRemove={openRemoveDialog}
          onSave={handleSubmit}
          ActionComponent={AllowedExpensesAndEstimatesFooter}
          actionComponentCustomProps={actionComponentCustomProps}
          saveable={canViewExpenseCodes && !isSubmitting}
        >
          <EditTitle>
            <FormattedMessage id="expenseBillPlan.edit.title" />
          </EditTitle>
          <EditContent>
            <ExpenseBillPlanEditContent
              chartDates={chartDates}
              dateRange={dateRange}
              editable={editable}
              onNext={onNext}
              onPrevious={onPrevious}
              projectEndDate={projectEndDate}
              projectPermissions={projectPermissions}
              projectStartDate={projectStartDate}
              setDate={setDate}
            />
          </EditContent>
        </Edit>
      </EditableCard>
      {removeDialogOpen && (
        <RemoveEntityConfirmationDialog
          resourceKeys={removeDialogResourceKeys}
          open={removeDialogOpen}
          onClose={closeRemoveDialog}
          onConfirm={onDeleteClick}
        >
          <DialogContentText color="textPrimary">
            <FormattedMessage id="expenseBillPlan.removeDialog.message" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

ExpenseBillPlanEditableCard.propTypes = {
  editable: PropTypes.bool,
  estimatedAmount: PropTypes.object,
  onCancelCallback: PropTypes.func,
  onDeleteClick: PropTypes.func,
  projectEndDate: PropTypes.string,
  projectPermissions: PropTypes.object,
  projectStartDate: PropTypes.string,
  subTitle: PropTypes.node
};

export default ExpenseBillPlanEditableCard;
