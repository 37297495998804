import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { RemoveCircleSharp } from '@material-ui/icons';
import { useProjectLinkProjectsContext } from '~/modules/projects/project/ProjectLinksTab/ProjectLinkProjectsContext';
import { useProjectLinkActions } from '~/modules/projects/project/ProjectLinksTab/hooks/useProjectLinkActions';
import DeleteConfirmationDialog from '~/modules/projects/project/ProjectLinks/DeleteConfirmationDialog';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%'
  },
  linkTypeLabel: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1)
  },
  deleteButton: {
    padding: theme.spacing(0)
  }
}));

export const ProjectLinkType = ({ field, record }) => {
  const classes = useStyles();
  const link = record[field];
  const { edit } = useProjectLinkProjectsContext();

  const {
    onRemoveLinkClick,
    onRemoveConfirm,
    closeRemoveDialog,
    isRemoving,
    showRemoveDialog
  } = useProjectLinkActions();

  return (
    <>
      <div className={classes.container}>
        <>
          <Typography className={classes.linkTypeLabel}>
            <FormattedMessage id={`projectLink.${link.linkType}`} />
          </Typography>
          <IconButton
            disabled={!edit}
            className={classes.deleteButton}
            onClick={onRemoveLinkClick}
          >
            <RemoveCircleSharp />
          </IconButton>
        </>
      </div>
      {showRemoveDialog && (
        <DeleteConfirmationDialog
          open={showRemoveDialog}
          onRemove={() => onRemoveConfirm(link)}
          onCancel={closeRemoveDialog}
          link={link}
          isLoading={isRemoving}
        />
      )}
    </>
  );
};

ProjectLinkType.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default ProjectLinkType;
