import React from 'react';
import PropTypes from 'prop-types';
import { EditorPopoverContent, EditorPopoverFooter } from './components';

const ResourceManagerRoleEditor = ({ onClose }) => (
  <>
    <EditorPopoverContent />
    <EditorPopoverFooter onClose={onClose} />
  </>
);

ResourceManagerRoleEditor.propTypes = {
  onClose: PropTypes.func
};

export default ResourceManagerRoleEditor;
