import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { ResourceRequestStatus } from '~/types';
import {
  specificResourceRequestWithAllocationsFragment,
  mergedResourceRequestsFragment
} from '~/modules/resourcing/common/fragments';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import { getSessionStorage } from '~/modules/common/hooks';
import { GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES } from '~/modules/projects/resourcing-plan/ResourceRequestChart/hooks/useFetchResourcePlanUsersAndRoles';
import { getUpdatedAllocatedUserListFromAllocations } from '~/modules/resourcing/common/util/allocationCacheUpdates';
import {
  tryResourcePlanAllocatedUsersAndRolesCachedQuery,
  GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES
} from '~/modules/projects/resourcing-plan/ResourceRequestChart/hooks/useFetchAllocatedUsersAndRoles';
import { RESOURCE_SUMMARY_COUNT_QUERY } from '~/modules/resourcing/common/hooks/useResourcePlanSummaryCounts';
import useResourceAllocationChartTabStateContext from '~/modules/resourcing/hooks/useResourceAllocationChartTabStateContext';
import { RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_SCHEDULE } from '~/modules/resourcing/hooks/useResourceRequests';

const requestStatusList = [
  ResourceRequestStatus.Allocationrejected,
  ResourceRequestStatus.Draft,
  ResourceRequestStatus.Rejected,
  ResourceRequestStatus.Submitted,
  ResourceRequestStatus.Tentative,
  ResourceRequestStatus.Tobehired
];

export const COMPLETE_AND_MERGE_RESOURCE_REQUEST = gql`
  mutation markResourceRequestAsCompleteAndMerge(
    $input: MarkResourceRequestAsCompleteAndMergeInput!
  ) {
    markResourceRequestAsCompleteAndMerge(input: $input) {
      resourceRequests {
        mergedResourceRequests {
          id
        }
        ...SpecificResourceRequestWithAllocation
        mergedResourceRequests {
          ...MergedResourceRequestsFragment
        }
      }
    }
  }
  ${mergedResourceRequestsFragment}
  ${specificResourceRequestWithAllocationsFragment}
`;

export const tryLoadCachedSummaryCountQuery = ({
  proxy,
  requestAccessLevel
}) => {
  try {
    return proxy.readQuery({
      query: RESOURCE_SUMMARY_COUNT_QUERY,
      variables: {
        requestAccessLevel,
        groupBy: 'PROJECT'
      }
    });
  } catch (e) {
    return null;
  }
};

const updateResourcePlanSummaryTabCountCache = ({
  proxy,
  existingRequestStatus,
  requestAccessLevel
}) => {
  const cachedSummaryTabCount = tryLoadCachedSummaryCountQuery({
    proxy,
    requestAccessLevel
  });

  if (!cachedSummaryTabCount) return;

  const {
    resourcePlansSummaryForCurrentTenant: { proposedCount }
  } = cachedSummaryTabCount;

  const newSummaryTabCounts =
    existingRequestStatus === ResourceRequestStatus.Tentative
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          proposedCount: proposedCount - 1
        }
      : cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant;

  proxy.writeQuery({
    query: RESOURCE_SUMMARY_COUNT_QUERY,
    variables: {
      groupBy: 'PROJECT',
      requestAccessLevel
    },
    data: {
      resourcePlansSummaryForCurrentTenant: {
        ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
        ...newSummaryTabCounts
      }
    }
  });
};

export const tryLoadCachedResourceRequestsForMergeQuery = ({
  proxy,
  projectUri,
  optimistic
}) => {
  try {
    return proxy.readQuery(
      {
        query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
        variables: {
          projectUri,
          requestStatusList
        }
      },
      optimistic
    );
  } catch (e) {
    return null;
  }
};

export const tryLoadCachedResourceRequestsForMergeInResourcingQuery = ({
  proxy,
  variables
}) => {
  try {
    return proxy.readQuery({
      query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_SCHEDULE,
      variables
    });
  } catch (e) {
    return null;
  }
};

const updateResourceAllocationCache = ({ resourceAllocations, proxy }) => {
  const variables = getSessionStorage(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES,
    null
  );

  const cachedResults = tryResourcePlanAllocatedUsersAndRolesCachedQuery({
    proxy,
    variables
  });

  if (!cachedResults) return;

  const userList = cachedResults
    ? getUpdatedAllocatedUserListFromAllocations({
        newAllocations: resourceAllocations,
        cachedAllocatedUsers:
          cachedResults.project.resourcePlanAllocatedUsersAndRoles
      })
    : [];

  proxy.writeQuery({
    query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
    variables,
    data: {
      project: {
        ...cachedResults?.project,
        resourcePlanAllocatedUsersAndRoles: userList
      }
    }
  });
};

export const updateCacheForCompletedAndMergedRequestsOnResourcingPage = ({
  existingResourceRequest,
  cacheUpdateParams
}) => (proxy, mutationResponse) => {
  const {
    data: {
      markResourceRequestAsCompleteAndMerge: { resourceRequests }
    }
  } = mutationResponse;

  const { resourceRequestsQueryVariables } = cacheUpdateParams;

  const cachedResourceRequests = tryLoadCachedResourceRequestsForMergeInResourcingQuery(
    {
      proxy,
      variables: cacheUpdateParams.resourceRequestsQueryVariables
    }
  );

  if (!cachedResourceRequests || !resourceRequests) return;

  proxy.writeQuery({
    query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_SCHEDULE,
    variables: resourceRequestsQueryVariables,
    data: {
      resourceRequests: {
        ...cachedResourceRequests.resourceRequests,
        resourceRequests: [
          ...cachedResourceRequests.resourceRequests.resourceRequests,
          ...resourceRequests.filter(
            resourceRequest =>
              resourceRequest.requestStatus ===
                ResourceRequestStatus.Complete &&
              !cachedResourceRequests.resourceRequests.resourceRequests
                .map(cacheRequest => cacheRequest.id)
                .includes(resourceRequest.id)
          )
        ]
      }
    }
  });

  resourceRequests.forEach(() => {
    updateResourcePlanSummaryTabCountCache({
      proxy,
      existingRequestStatus: existingResourceRequest.requestStatus,
      requestAccessLevel: resourceRequestsQueryVariables.requestAccessLevel
    });
  });
};

export const updateCacheForCompletedAndMergedRequests = ({
  projectUri,
  existingResourceRequest,
  allocationChartRef
}) => (proxy, mutationResponse) => {
  const {
    data: {
      markResourceRequestAsCompleteAndMerge: { resourceRequests }
    }
  } = mutationResponse;

  const cachedResourceRequests = tryLoadCachedResourceRequestsForMergeQuery({
    proxy,
    projectUri
  });

  if (!cachedResourceRequests || !resourceRequests) return;

  const newUsers = resourceRequests
    .map(resourceRequest =>
      resourceRequest.resourceAllocations.map(allocation => allocation.user)
    )
    .flat();

  const { resourceRequests: data } = cachedResourceRequests;

  proxy.writeQuery({
    query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
    variables: {
      projectUri,
      requestStatusList
    },
    data: {
      resourceRequests: {
        ...data,
        resourceRequests: data.resourceRequests.map(req =>
          req.id === existingResourceRequest.id
            ? {
                ...req,
                requestStatus: ResourceRequestStatus.MergedArchived
              }
            : req
        )
      }
    }
  });

  resourceRequests.forEach(resourceRequest =>
    updateResourceAllocationCache({
      resourceAllocations: resourceRequest.resourceAllocations,
      proxy
    })
  );

  allocationChartRef?.current.handleResourceAllocationUserChange({
    newUsers: newUsers.map(user => user.user)
  });
  allocationChartRef?.current.handleLoadingStateOnResourceRequestAccept({
    newUsers
  });
};

const useMarkResourceRequestAsCompleteAndMerge = ({
  resourceRequest,
  allocationChartRef,
  onCompleted,
  cacheUpdateParams
}) => {
  const {
    refetchQueries = [],
    updateMergeCache,
    isOptimistic = false
  } = cacheUpdateParams;

  const [markResourceRequestAsCompleteAndMerge, { loading }] = useMutation(
    COMPLETE_AND_MERGE_RESOURCE_REQUEST,
    {
      ...(onCompleted && {
        onCompleted: param => {
          onCompleted(param.markResourceRequestAsCompleteAndMerge);
        }
      })
    }
  );

  const { selectedChartTab } = useResourceAllocationChartTabStateContext();

  const onMarkResourceRequestAsCompleteAndMerge = useCallback(() => {
    markResourceRequestAsCompleteAndMerge({
      variables: {
        notifyOnNetworkStatusChange: true,
        input: {
          resourceRequestId: resourceRequest.id
        }
      },
      refetchQueries,
      ...(isOptimistic && {
        optimisticResponse: {
          __typename: 'Mutation',
          markResourceRequestAsCompleteAndMerge: {
            __typename: 'MarkResourceRequestAsCompleteAndMergeResult',
            isOptimistic,
            resourceRequests: [
              {
                __typename: 'ResourceRequest',
                ...resourceRequest,
                mergedResourceRequests: [],
                requestStatus: ResourceRequestStatus.MergedArchived
              }
            ]
          }
        }
      }),
      update: updateMergeCache
        ? updateMergeCache({
            projectUri: resourceRequest.projectUri,
            existingResourceRequest: resourceRequest,
            allocationChartRef,
            chartSelectedTab: selectedChartTab,
            cacheUpdateParams
          })
        : null
    });
  }, [
    markResourceRequestAsCompleteAndMerge,
    resourceRequest,
    refetchQueries,
    isOptimistic,
    updateMergeCache,
    allocationChartRef,
    selectedChartTab,
    cacheUpdateParams
  ]);

  return {
    onMarkResourceRequestAsCompleteAndMerge,
    isLoading: loading
  };
};

export default useMarkResourceRequestAsCompleteAndMerge;
