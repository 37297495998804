import { PropTypes } from 'prop-types';
import React from 'react';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import {
  useListColumns,
  useListFooters,
  useCardStyles,
  useListTableThemeProviderStyles,
  allowedExpensesAndEstimatesColumnsValues
} from './hooks';

export const AllowedExpensesAndEstimatesListTable = ({
  chartDates,
  editable,
  projectStartDate,
  projectEndDate,
  totals,
  totalEstimatesColumnLabel,
  visibleColumns,
  columnClasses,
  providerClasses: providerClassesOverride,
  records
}) => {
  const providerClasses = useListTableThemeProviderStyles({
    classes: providerClassesOverride
  });
  const cardClasses = useCardStyles();

  const columns = useListColumns({
    chartDates: chartDates || [],
    editable,
    projectStartDate,
    projectEndDate,
    totalEstimatesColumnLabel,
    visibleColumns,
    columnClasses
  });

  const footers = useListFooters({
    chartDates: chartDates || [],
    visibleColumns,
    columnClasses
  });

  return (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTable
        variant="table"
        records={records}
        classes={cardClasses}
        columns={columns}
        footers={footers}
        totals={totals}
      />
    </ListTableThemeProvider>
  );
};

AllowedExpensesAndEstimatesListTable.propTypes = {
  chartDates: PropTypes.array,
  editable: PropTypes.bool,
  projectEndDate: PropTypes.string,
  projectStartDate: PropTypes.string,
  totals: PropTypes.object,
  totalEstimatesColumnLabel: PropTypes.node,
  visibleColumns: PropTypes.arrayOf(
    PropTypes.oneOf(allowedExpensesAndEstimatesColumnsValues)
  ).isRequired,
  columnClasses: PropTypes.object,
  providerClasses: PropTypes.object,
  records: PropTypes.array
};

export default AllowedExpensesAndEstimatesListTable;
