import { Checkbox, makeStyles, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Decimal } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  alertContainer: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  alertTitle: {
    marginBottom: '0px !important'
  },
  alert: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(-5.5)
  },
  resourceChange: {
    padding: theme.spacing(2, 1, 1),
    width: '100%'
  },
  noResourceAllocation: {
    fontSize: theme.typography.body2.fontSize
  },
  confirmation: {
    padding: theme.spacing(0, 1, 1, 1),
    color: theme.palette.text.secondary
  },
  checkbox: {
    marginTop: theme.spacing(3)
  },
  dateRange: {
    whiteSpace: 'nowrap'
  }
}));

export const ChangeTaskResourceConfirmationArea = ({
  existingResource,
  selectedResource,
  taskResourceUserAllocation,
  taskAllocationDateRange
}) => {
  const classes = useStyles();
  const { totalHours: taskResourceUserAllocationHours } =
    taskResourceUserAllocation || {};
  const resourceDisplayText = useMemo(
    () => ({
      resourceDisplayText: selectedResource?.displayText
    }),
    [selectedResource]
  );

  const hasSufficientProjectAllocationHours = false;

  const allocationHours = useMemo(
    () => ({
      allocationHours: <Decimal value={taskResourceUserAllocationHours} />
    }),
    [taskResourceUserAllocationHours]
  );

  return (
    <>
      <Typography className={classes.resourceChange}>
        <FormattedMessage
          id="changeTaskResourceDialog.resourceChange"
          values={resourceDisplayText}
        />
      </Typography>
      {taskResourceUserAllocation &&
        (hasSufficientProjectAllocationHours ? (
          <Alert
            severity="info"
            icon={false}
            className={classes.alertContainer}
          >
            <Typography className={classes.noResourceAllocation}>
              <FormattedMessage
                id="changeTaskResourceDialog.allocationChangeMessage"
                values={allocationHours}
              />
            </Typography>
          </Alert>
        ) : (
          <Alert
            severity="warning"
            icon={false}
            className={classes.alertContainer}
          >
            <Typography className={classes.noResourceAllocation}>
              <FormattedMessage
                id="changeTaskResourceDialog.noResourceAllocation"
                values={resourceDisplayText}
              />
              <span className={classes.dateRange}>
                <FormattedMessage
                  id="changeTaskResourceDialog.taskAllocationDateRange"
                  values={taskAllocationDateRange}
                />
              </span>
            </Typography>
            <Checkbox className={classes.checkbox} />
            <div className={classes.alert}>
              <AlertTitle className={classes.alertTitle}>
                <FormattedMessage id="changeTaskResourceDialog.removeTaskAllocation" />
              </AlertTitle>
              <FormattedMessage id="changeTaskResourceDialog.dontAllocateMessage" />
            </div>
          </Alert>
        ))}
      <Typography className={classes.confirmation}>
        <FormattedMessage id="changeTaskResourceDialog.confirmation" />
      </Typography>
    </>
  );
};

ChangeTaskResourceConfirmationArea.propTypes = {
  selectedResource: PropTypes.object,
  existingResource: PropTypes.object,
  taskResourceUserAllocation: PropTypes.object,
  taskAllocationDateRange: PropTypes.object
};

export default ChangeTaskResourceConfirmationArea;
