import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import get from 'lodash.get';
import { CardExpansionPanel } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import AddResourceEstimateDialog from './AddResourceEstimateDialog';
import TaskEstimateTitle from './TaskEstimateTitle';
import TaskResourceEstimatesContent from './TaskResourceEstimatesContent';
import useTaskResourceEstimates from './hooks/useTaskResourceEstimates';

export const useExpansionStyles = makeStyles(theme => ({
  expansionPanel: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    margin: '0 !important'
  },
  expansionPanelSummary: {
    alignItems: 'center',
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white
  },
  expansionPanelSummaryContent: {
    padding: 0
  },
  expandIcon: {
    padding: theme.spacing(1.5, 2, 1.5, 0.5)
  }
}));

export const TaskResourceEstimatesExpansionPanel = ({ task }) => {
  const expansionClasses = useExpansionStyles();
  const { open, openDialog, closeDialog } = useDialogState();
  const [currentPage, setCurrentPage] = useState(1);

  const onAddResourceClick = useCallback(
    event => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const { loading, resourceEstimates, refetch } = useTaskResourceEstimates({
    taskId: task.id,
    page: currentPage
  });

  const onRemoveResourceEstimateSuccess = useCallback(
    async ({ setFormikFieldValue }) => {
      const { data: refetchedData } = await refetch({
        fetchPolicy: 'network-only'
      });

      setFormikFieldValue(
        'resourceEstimates',
        get(refetchedData, 'task.resourceEstimates', [])
      );
    },
    [refetch]
  );

  const titleComponentProps = useMemo(
    () => ({
      loading,
      onAddResourceClick,
      currentPage,
      setCurrentPage
    }),
    [loading, onAddResourceClick, currentPage]
  );

  const onClose = useCallback(
    (_, reason) => {
      if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
        closeDialog();
      }
    },
    [closeDialog]
  );

  const initialValues = useMemo(() => ({ resourceEstimates, currentPage }), [
    resourceEstimates,
    currentPage
  ]);

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <>
        <CardExpansionPanel
          classes={expansionClasses}
          elevation={0}
          TitleComponent={TaskEstimateTitle}
          titleComponentProps={titleComponentProps}
          defaultExpanded
        >
          <TaskResourceEstimatesContent
            loading={loading}
            task={task}
            projectSlug={task.projectReference.slug}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRemoveResourceEstimateSuccess={onRemoveResourceEstimateSuccess}
          />
        </CardExpansionPanel>
        {open && (
          <AddResourceEstimateDialog
            task={task}
            isOpen={open}
            onClose={onClose}
          />
        )}
      </>
    </Formik>
  );
};

TaskResourceEstimatesExpansionPanel.propTypes = {
  task: PropTypes.object.isRequired
};

export default TaskResourceEstimatesExpansionPanel;
