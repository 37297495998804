import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Divider } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { NoDataItem } from '~/modules/common/components';
import PageOutOfBoundsMessage from '~/modules/common/components/ListTable/PageOutOfBoundsMessage';
import TaskEstimateRow from './TaskEstimateRow';
import TaskResourceEstimatesContentSkeleton from './TaskResourceEstimatesContentSkeleton';

const useDividerStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0, 1)
  }
}));

const useRowStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2),
    minHeight: theme.spacing(5)
  }
}));

export const TaskResourceEstimatesContent = ({
  loading = false,
  task,
  projectSlug,
  currentPage,
  setCurrentPage,
  onRemoveResourceEstimateSuccess
}) => {
  const dividerClasses = useDividerStyles();
  const rowClasses = useRowStyles();

  const {
    values: { resourceEstimates }
  } = useFormikContext();

  const assignmentCount = resourceEstimates?.length || 0;

  return (
    <div className={rowClasses.container}>
      <Divider className={dividerClasses.root} />
      {!loading ? (
        assignmentCount === 0 && currentPage > 1 ? (
          <PageOutOfBoundsMessage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : assignmentCount === 0 ? (
          <NoDataItem>
            <FormattedMessage id="taskResourceEstimates.noResourceAssignments" />
          </NoDataItem>
        ) : (
          resourceEstimates?.map((resourceEstimate, index) => (
            <>
              {resourceEstimate.loading ? (
                <Skeleton height={120} width="100%" />
              ) : (
                <TaskEstimateRow
                  key={resourceEstimate.id}
                  resourceEstimate={resourceEstimate}
                  task={task}
                  rowIndex={index}
                  projectSlug={projectSlug}
                  onRemoveResourceEstimateSuccess={
                    onRemoveResourceEstimateSuccess
                  }
                />
              )}

              <Divider className={dividerClasses.root} />
            </>
          ))
        )
      ) : (
        <TaskResourceEstimatesContentSkeleton />
      )}
    </div>
  );
};

TaskResourceEstimatesContent.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.object,
  projectSlug: PropTypes.string.isRequired,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  onRemoveResourceEstimateSuccess: PropTypes.func
};

export default TaskResourceEstimatesContent;
